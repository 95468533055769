<!-- 
	This is the dashboard page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

 <template>
	<div>

        <a-row :gutter="24" class="px-10" style="margin-top: 0px;">
            <a-col :span="24" :md="24" :lg="14" :xl="14" class="mb-24">
                <a-badge 
                    v-for="option in filterItems"
                    :key="option.uuid"
                    style="font-size: 14px !important; margin: 2px 3px; padding: 5px;" 
                    :count="`${option.name}    ${option.icon}`" 
                    @click="onSelectFilterItem(option.uuid)"
                    :number-style="pagination.filter == option.uuid ? 
                        {backgroundColor: '#734EBF !important', color: '#FFFFFF', paddingTop: '5px', paddingRight: '18px', paddingLeft: '18px', height: '32px', fontSize: '14px'} : 
                        {border: '1px solid #734EBF !important', color: '#734EBF', backgroundColor: 'transparent !important', paddingTop: '5px', paddingRight: '18px', paddingLeft: '18px', height: '32px', fontSize: '14px'}"/>
            </a-col>
        
			<a-col :span="24" :md="24" :lg="10" :xl="10" class="mb-24 pt-10 text-right">
				<a-pagination type="success" class="btn btn-silabu" v-model="currentPage" :total="pagination.total" :pageSize="pagination.perPage" show-less-items />
			</a-col>
		</a-row>

		<div v-if="!loadingData" class="mb-20">

			<a-row :gutter="24" class="px-10"  v-if="pagination.filter != 'attempts'">
				<a-col :span="24" :xs="24" :sm="24" :md="12" :lg="8" :xl="6" class="mb-24 pb-10" v-for="qui in quizzes" :key="qui.uuid">

                    <a-card id="zeroPaddingCard" class="p-0 m-0 pb-15" style="border-radius: 5px !important;">
                        <a class="text-dark p-0 m-0" style="text-decoration: none !important;" :href="`/quizzes/view/${qui.uuid}`">
                            <a-row :gutter="24">
                                <a-col :span="24">
                                    <div class="icon">
                                        <img id="classImg" width="100%" height="200px" :src="qui.thumbnail != null ? qui.thumbnail : 'https://media.istockphoto.com/id/590073366/photo/books-of-knowledge.jpg?s=612x612&w=0&k=20&c=h-uY-AOk62qTsKOxoa_vPp0BZPuYeDs_kV0bU4H0jaA='" alt="">
                                    </div>
                                </a-col>
                            </a-row>
                            <a-row :gutter="24" class="px-20">
                                <a-col :span="20">
                                    <h6 class="mt-10 pt-4 pl-2">{{ qui.title.length > 15 ? `${qui.title.substring(0, 15)}...` : qui.title }}</h6>
                                </a-col>

                                <a-col :span="4">
                                    <svg class="mt-10 pt-5 text-gray-800 dark:text-white" style="width: 18px;" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6C6.5 1 1 8 5.8 13l6.2 7 6.2-7C23 8 17.5 1 12 6Z"/>
                                    </svg>
                                </a-col>

                                
                            </a-row>

                            <a-row :gutter="24" class="px-20">
                                <a-col :span="16" :md="16" :lg="14" :xl="14">
                                    <!-- <a-avatar :src="qui.creator != null && qui.creator.profilePicUrl ? qui.creator.profilePicUrl : ''" />&nbsp;&nbsp; -->
                                    Duration: {{ qui.duration }} minutes
                                    <!-- <a-row :gutter="24" class="">
                                        <a-col :span="24" :md="24" :lg="24" :xl="24">
                                            Duration: {{ qui.duration }} minutes
                                        </a-col>
                                        <a-col :span="24" :md="24" :lg="24" :xl="24" class="mt-4 pt-4">
                                            Time: {{ $Moment(qui.updatedAt).format("DD MMM hh:mm a") }}
                                        </a-col>
                                    </a-row> -->

                                </a-col>
                                <a-col :span="8" :md="8" :lg="10" :xl="10" class="text-right">
                                    <h6 class="text-silabu pt-0">{{ qui.price != null && qui.price != 0 ? `${qui.price}/=` : 'FREE' }}</h6>
                                </a-col>
                            </a-row>

                            <!-- <a-row :gutter="24" class="mt-10 px-20">
                                <a-col :span="24" :md="14" :lg="14" :xl="14">
                                    <a-icon type="team" class="pl-5" theme="outlined" />&nbsp;
                                    <span><strong>{{ qui.slots + 1 - qui.membersCount }}</strong></span> <small>Slots remain </small>
                                </a-col>

                                <a-col :span="24" :md="10" :lg="10" :xl="10" class="text-right">
                                    <h6 class="text-silabu pr-5">{{ qui.price != null && qui.price != 0 ? `${qui.price}/=` : 'FREE' }}</h6>
                                </a-col>
                            </a-row> -->
                        </a>
                    </a-card>

				</a-col>
            </a-row>

            <a-row :gutter="24" class="px-10" v-if="pagination.filter == 'attempts'">
                <a-col :span="24" :xs="24" :sm="24" :md="12" :lg="8" :xl="6" class="mb-24 pb-10" v-for="qui in quizzes" :key="qui.uuid">

                    <a-card id="zeroPaddingCard" class="p-0 m-0 pb-15">
                        <router-link :to="`/quizzes/view/${qui.quiz.uuid}/results`" @click="e => e.preventDefault()">
                            <a-row :gutter="24">
                                <a-col :span="24">
                                    <div class="icon">
                                        <img id="classImg" width="100%" height="200px" :src="qui.quiz.thumbnail != null ? qui.quiz.thumbnail : 'https://media.istockphoto.com/id/590073366/photo/books-of-knowledge.jpg?s=612x612&w=0&k=20&c=h-uY-AOk62qTsKOxoa_vPp0BZPuYeDs_kV0bU4H0jaA='" alt="">
                                    </div>
                                </a-col>
                            </a-row>
                            <a-row :gutter="24" class="px-20">
                                <a-col :span="20">
                                    <h6 class="mt-10 pt-4 pl-2">{{ qui.quiz.title.length > 15 ? `${qui.quiz.title.substring(0, 15)}...` : qui.quiz.title }}</h6>
                                </a-col>

                                <a-col :span="4">
                                    <svg class="mt-10 pt-5 text-gray-800 dark:text-white" style="width: 18px;" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6C6.5 1 1 8 5.8 13l6.2 7 6.2-7C23 8 17.5 1 12 6Z"/>
                                    </svg>
                                </a-col>

                                
                            </a-row>

                            <a-row :gutter="24" class="px-20">
                                <a-col :span="16" :md="16" :lg="14" :xl="14">
                                    <!-- <a-avatar :src="qui.quiz.creator != null && qui.quiz.creator.profilePicUrl ? qui.quiz.creator.profilePicUrl : ''" />&nbsp;&nbsp; -->
                                    Duration: {{ qui.quiz.duration }} minutes
                                    <!-- <a-row :gutter="24" class="">
                                        <a-col :span="24" :md="24" :lg="24" :xl="24">
                                            Duration: {{ qui.quiz.duration }} minutes
                                        </a-col>
                                        <a-col :span="24" :md="24" :lg="24" :xl="24" class="mt-4 pt-4">
                                            Time: {{ $Moment(qui.quiz.updatedAt).format("DD MMM hh:mm a") }}
                                        </a-col>
                                    </a-row> -->

                                </a-col>
                                <a-col :span="8" :md="8" :lg="10" :xl="10" class="text-right">
                                    <h6 class="text-silabu pt-0">{{ qui.quiz.price != null && qui.quiz.price != 0 ? `${qui.quiz.price}/=` : 'FREE' }}</h6>
                                </a-col>
                            </a-row>

                            <!-- <a-row :gutter="24" class="mt-10 px-20">
                                <a-col :span="24" :md="14" :lg="14" :xl="14">
                                    <a-icon type="team" class="pl-5" theme="outlined" />&nbsp;
                                    <span><strong>{{ qui.slots + 1 - qui.membersCount }}</strong></span> <small>Slots remain </small>
                                </a-col>

                                <a-col :span="24" :md="10" :lg="10" :xl="10" class="text-right">
                                    <h6 class="text-silabu pr-5">{{ qui.price != null && qui.price != 0 ? `${qui.price}/=` : 'FREE' }}</h6>
                                </a-col>
                            </a-row> -->
                        </router-link>
                    </a-card>

				</a-col>

			</a-row>

		</div>


        <div v-if="!loadingData && quizzes.length == 0">

            <a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="px-20" style="min-height: 50vh">

                <a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

                    <img src="../../../public/images/no_data.png" alt="" width="100%" style="" class=""> 

                    <h6 class="mt-5" style="font-weight: 600; color: #8C8C8C">No quizzes found</h6>
                    
                </a-col>

            </a-row>
        </div>

		<div v-if="loadingData">

			<a-row type="flex" :gutter="24" justify="space-around" align="middle" class="px-20" style="min-height: 60vh">

				<a-col :span="24" :md="24" :lg="24" :xl="24" class="text-center  justify-items-center">

					<a-spin class="text-primary" size="large"/>

				</a-col>

			</a-row>
		</div>

		<a-modal v-model="filter.showModal" :footer="null">
			
			<a-row :gutter="[24, 24]" class="mt-20 mb-0 pb-0">
				<a-col :span="24" class="my-0 py-0">
					<h6 style="font-weight: normal; font-size: 18px;" class="text-center">Select all options that apply to you</h6>
				</a-col>
			</a-row>

            <!-- <a-row :gutter="[24, 24]" class="mt-10 mb-0 pb-0">
				<a-col :span="24" class="">
                    <label class=""><strong>Type</strong></label>
                </a-col>
                <a-col :span="24" class="mt-0 pt-0">
                    <a-radio-group v-model="filter.type">
                        <a-radio :value="'classes'">Classes</a-radio>
                        <a-radio :value="'notes'">Notes</a-radio>
                        <a-radio :value="'quizzes'">Quizzes</a-radio>
                    </a-radio-group>
				</a-col>
			</a-row> -->

            <a-row :gutter="[24, 24]" class="mt-10 mb-0 pb-0">
				<a-col :span="24" class="">
                    <label class="mb-0 pb-0"><strong>Price</strong></label>
                </a-col>
                <a-col :span="24" class="mt-5 pt-0">
                    <a-slider class="mt-0 pt-0 mb-0 pb-0" v-model="filter.price" :tooltip-open="true" :step="slider.step" :max="slider.max" :trackStyle="{background: '#734EBF', color: '#734EBF'}"/>
                    <p style="font-size: 12px; position: absolute; right: 20px; top: -24px;">Less than: TZS <strong>{{ filter.price.toLocaleString() }}</strong></p>
				</a-col>
			</a-row>


            <a-row :gutter="[24, 24]" class="mt-0 mb-0 pb-0">
				<a-col :span="24" class="mb-5 pb-0">
                    <label class="mb-0 pb-0"><strong>Tutor’s Rating</strong></label>
                </a-col>
                <a-col :span="24" class="mt-0 pt-0">
                    <a-rate class="mt-0 pt-0" v-model="filter.tutorRating" />
				</a-col>
			</a-row>


            <a-row :gutter="[24, 24]" class="mt-0 mb-0 pb-0">
				<a-col :span="24" class="">
                    <label class=""><strong>Start Time</strong></label>
                </a-col>
                <a-col :span="24" class="mt-0 pt-0">
                    <a-radio-group v-model="filter.startTime">
                        <a-radio :value="'today'">Starting Today</a-radio>
                        <a-radio :value="'week'">Starting Next week</a-radio>
                        <a-radio :value="'weeks'">Starting in 2 weeks</a-radio>
                    </a-radio-group>
				</a-col>
			</a-row>

			<a-row :gutters="24" class="mt-20 pt-20">
				<a-col :span="24" class="text-right">
					<a-button id="textBtn" type="text" class="text-primary txtBtn" @click="clearFilter">
						Clear all filters
					</a-button>
					&nbsp;&nbsp;
					<a-button type="primary" class="text-white txtBtn" style="" @click="submitFilter">
						Appy filters
					</a-button>
				</a-col>
			</a-row>
			
		</a-modal>

	</div>
</template>

<script>
	import {DoubleBounce} from 'vue-loading-spinner'
	import WidgetCounter from '../../components/Widgets/WidgetCounter' ;

	export default ({
		
		components: {
			DoubleBounce,
			WidgetCounter,
		},

		data() {
			return {

				loadingData: false,

				quizzes: [],
				currentPage: 1,
                pagination: {
					search: '',
                    filter: 'all',
                    perPage: 12,
                    currentPage: 1,
                    totalPages: 1,
                    perPageOptions: [10, 20, 30, 50],
                    total: 0
                },

				slider: {
                    max: 40000,
                    min: 0,
                    step: 500
                },

                filterItems: [
                    { uuid: 'all', name: 'All Quizzes ', icon: ' 🧩'},
                    { uuid: 'attempts', name: 'Done Quizzes ', icon: ' ✅'},
                    { uuid: 'favorites', name: 'Wishlist', icon: ' ❤️' },
                ],

                filter: {
                    loading: false,
                    showModal: false,
                    isActive: false,
                    type: null,
                    price: 20000,
                    tutorRating: 3,
                    startTime: null,
                },

                educationLevel: null,
      		}
		},
		watch: {
            // pagination: {
				
            //     handler() {
            //         // this.getQuizzes();
			// 		console.log(this.pagination.currentPage)
            //     },
            // },
			currentPage: {
                handler() {
					console.log(this.currentPage)
					this.pagination.currentPage = this.currentPage;
					this.getQuizzes()
                },
            },
            deep: true,
        },
		mounted () {
			let breadcrumbs = [
				{ title: 'Quizzes', url: '/quizzes', isActive: true },
			];
			
			this.$root.$refs.Breadcrumbs.initializeBreadCrumbs(breadcrumbs)
		},
		created() {
            this.$root.$refs.Quizzes = this;
            this.initializeFilter();
			this.getUserAcademicDetails()
		},
		methods: {

			submitSearch(search) {
                this.pagination.search = search;
                this.pagination.currentPage = 1;
				this.getQuizzes();
			},

			cancelSearch() {
				this.pagination.search = '';
				this.getQuizzes();
			},

			launchFilter() {
                this.filter.showModal = true;
            },

            clearFilter() {
                this.filter.isActive = false;
                this.filter.showModal = false;
                this.getQuizzes();
            },


            submitSearchFilter(filter) {
                this.filter.loading = false;
                this.pagination.search = filter.search;
                this.filter.price = filter.price;
                this.filter.isActive = filter.isActive;
                // this.filter.tutorRating = filter.tutorRating;
                // this.filter.startTime = filter.startTime,
				this.pagination.currentPage = 1;
				this.getQuizzes();
			},

            submitFilter() {
                this.filter.isActive = true;
                this.filter.showModal = false;
                this.getQuizzes();
            },

            async initializeFilter() {
                let filter = JSON.parse(await localStorage.getItem('filter'));

                if(filter != null) {
                    if(filter.navigate == true) {
                        filter.navigate = false;
                        await localStorage.setItem('filter', null)
                        
                        this.pagination.search = filter.search;
                        this.filter.price = filter.price;
                        this.filter.tutorRating = filter.tutorRating;
                        this.filter.startTime = filter.startTime;
                    }
                }
            },

            onSelectFilterItem(item) {
                this.pagination.filter = item;

                this.getQuizzes();
            },


            async getUserAcademicDetails() {

				const userInfo = await localStorage.getItem('user_details')

                this.loadingData = true;

				if(userInfo != null) {
					let userDetails = JSON.parse(userInfo);

					let url = `${this.$BACKEND_URL}/profiles?uuid=${userDetails.uuid}`;

					this.$AXIOS.get(url).then((response) => {

                        this.educationLevel = response.data.data.educationGrades[0].educationLevel;

						this.getQuizzes(response.data.data.educationGrades.length > 0 ? response.data.data.educationGrades[0].educationLevel : null);
						
					
					}).catch(error => {

						this.notify('error', error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!");
					
					})

				}
				
			},

			async getQuizzes(educationLevel) {

				this.loadingData = true;

                const { currentPage, perPage, search, filter } = this.pagination;

                let url = '';

                if(filter == 'all') {
                    if(search != null && search.length > 0) {
                        if(this.filter.isActive) {
                            url = `${this.$BACKEND_URL}/quizzes/?search=${search}&filter=true&price=<${this.filter.price}&page=${currentPage}&size=${perPage}`;
                        }else{
                            url = `${this.$BACKEND_URL}/quizzes/?search=${search}&page=${currentPage}&size=${perPage}`;
                        }
                    }else{
                        if(this.filter.isActive) {
                            url = `${this.$BACKEND_URL}/quizzes/?filter=true&price=<${this.filter.price}&page=${currentPage}&size=${perPage}`;
                        }else{
                            if(educationLevel != null) {
                                url = `${this.$BACKEND_URL}/quizzes/?educationLevels=${educationLevel}&page=${currentPage}&size=${perPage}`;
                            }else{
                                url = `${this.$BACKEND_URL}/quizzes/?page=${currentPage}&size=${perPage}`;
                            }
                        }
                    }
                }

                if(filter == 'favorites') {
                    if(search != null && search.length > 0) {                       
                        url = `${this.$BACKEND_URL}/quizzes/favorites?search=${search}&page=${currentPage}&size=${perPage}`;
                    }else{
                        url = `${this.$BACKEND_URL}/quizzes/favorites?page=${currentPage}&size=${perPage}`;
                    }
                }


                if(filter == 'attempts') {
                    if(search != null && search.length > 0) {
                        url = `${this.$BACKEND_URL}/quizzes/attempts?populate=true&search=${search}&page=${currentPage}&size=${perPage}`;
                    }else{
                        url = `${this.$BACKEND_URL}/quizzes/attempts?populate=true&page=${currentPage}&size=${perPage}`;
                    }
                }

                this.$AXIOS.get(url).then(async(response) => {
                    if (response.status == 200) {
						this.quizzes = response.data.data;
						this.pagination.totalPages = response.data.pagination != null ? response.data.pagination.totalPages : 1;
                        this.pagination.total = response.data.pagination != null ? response.data.pagination.totalItems : 0;
                        this.pagination.currentPage = response.data.pagination != null ? response.data.pagination.currentPage : 1;
						this.currentPage = response.data.pagination != null ? response.data.pagination.currentPage : 1;
                    }
					
					this.loadingData = false;
					
                }).catch(async(error) => {

                    console.log(error)
					
					this.loadingData = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },

		}
	})

</script>

<style>

.double-bounce1[data-v-33aee302], .double-bounce2[data-v-33aee302] {
	background-color: #734EBF !important;
	height: 70px;
	width: 70px;
}

.ant-pagination-item-active a {
    color: #FFFFFF !important;
	background-color: #734EBF;
}

#classImg {
	border-radius: 5px;
}

a:hover {
    color: inherit !important;
}
</style>